<template>
    <div>
        <b-row class="mt-2">
            <b-col sm="6" md="6">
                <span class="details-view-title">ASSETS-ON-LOCATIONS</span>
                <div class="details-view-subtitle">List of asset distribution among connections</div>
            </b-col>
            <b-col sm="6" md="4" offset-md="2" class="text-md-right">
                <b-input-group prepend="Show" append="/ Page">
                    <b-form-select :options="pageOptions" v-model="perPage" />
                </b-input-group>
            </b-col>
        </b-row>

        <b-table show-empty striped hover :items="dashboard.assetLocationSummary" :fields="fields"
            :current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc" :sort-direction="sortDirection" responsive>

            <template v-slot:cell(totalQuantity)="row">
                <div class="clearfix">
                    <div class="float-left numFont">
                        <strong>{{ row.item.totalQuantity }}</strong>
                    </div>
                </div>
            </template>

            <template v-slot:cell(assetPoolPercentage)="row">
                <div class="clearfix">
                    <div class="float-left numFont">
                        <strong>{{ row.item.assetPoolPercentage }}%</strong>
                    </div>
                    <div class="float-right">
                        <small class="text-muted">
                            {{ row.item.totalQuantity.toLocaleString() + ' / ' + totalAssets.toLocaleString() }}
                        </small>
                    </div>
                </div>
                <b-progress :value="getProgressPercentage(row.item)" class="progress-xs" />
            </template>
        </b-table>

        <b-row class="my-1">
            <b-col md="8" sm="12">
                <span class="total-display">Total:
                    <span class="numFont">{{ totalRows ? totalRows.toLocaleString() : 0 }}</span>
                </span>
            </b-col>
            <b-col md="4" sm="12">
                <b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
                    class="my-0" />
            </b-col>
        </b-row>

        <b-row class="mt-2">
            <b-col lg="4" md="6" sm="12">
                <b-button variant="outline-primary" @click="goToAssetPoolDistribution" class="mr-2">
                    View More
                </b-button>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    name: 'assets-on-location',
    props: {
        dashboard: {
            type: Object,
            required: true,
        },
        totalAssets: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            fields: [
                {
                    key: 'company',
                    label: 'Company',
                    sortable: true,
                },
                {
                    key: 'connections',
                    label: 'Storage Location',
                },
                {
                    key: 'assetType',
                    sortable: true,
                },
                {
                    key: 'totalQuantity',
                    label: 'Total',
                    sortable: true,
                },
                {
                    key: 'assetPoolPercentage',
                    label: 'Percentage',
                    sortable: false,
                },
            ],
            currentPage: 1,
            perPage: 5,
            totalRows: 0,
            pageOptions: [5, 10, 15, 25],
            sortBy: null,
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,

            isSuperAdmin: this.$store.getters.isSuperAdmin,
        };
    },
    watch: {
        dashboard: function () {
            this.totalRows = this.dashboard.assetLocationSummary.length;
        }
    },
    mounted() {

    },
    methods: {
        getProgressPercentage(item) {
            return (item.totalQuantity / this.totalAssets) * 100;
        },
        goToAssetPoolDistribution() {
            if (!this.isSuperAdmin) {
                this.$router.push({ path: '/asset-pool-distribution' });
            } else {
                this.$router.push({ path: '/admin/admin-asset-pool-distribution' });
            }
        }
    },
}
</script>

<style scoped></style>